<!-- eslint-disable import/no-cycle -->
<template>
  <div
    v-if="!status"
    style="height: 70vh !important;"
  >
    <b-card
      title="En cours de validation"
      img-src="https://images.unsplash.com/photo-1533745848184-3db07256e163?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1032&q=80"
      img-alt="Image"
      img-top
      tag="article"
      class="mb-2"
      style="height: 100% !important;"
    >
      <b-card-text>
        Votre compte a bien été créé mais est actuellement en cours de validation.Si vous n'avez pas renseigné de pièces
        veuillez bien le faire en cliquant sur le bouton ci dessous.
      </b-card-text>
      <router-link
        class="btn btn-primary p-1"
        to="/espace-expert/activate-account"
      >
        Renseignez vos pièces ICI
      </router-link>
    </b-card>
  </div>
  <div
    v-else
    style="height: 70vh !important;"
  >
    <b-row>
      <b-col md="12">
        <b-card
          title="Liste des expertises"
          no-body
        >
          <b-card-body>
            <b-row>
              <b-col md="12">
                <b-card
                  title="Liste des expertises"
                  no-body
                >
                  Liste des expertises
                </b-card>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="float-right mb-1 ml-1"
                  onclick="history.back()"
                >
                  <feather-icon icon="ArrowLeftCircleIcon" />
                  Retour
                </b-button>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Trier"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelect"
                class="mb-md-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">
                        aucun
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                  >
                    <option :value="false">
                      Croissant
                    </option>
                    <option :value="true">
                      Décroissant
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filtrer"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Rechercher"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Effacer
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>

          <b-table
            striped
            hover
            responsive
            small
            :busy="loader"
            :empty-filtered-text="'Aucune nouvelle plainte'"
            :empty-text="'Aucun résultat n\'a trouvé'"
            show-empty
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="expertisesSoumises"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #table-busy>
              <div class="d-flex justify-content-center mb-3">
                <b-spinner
                  style="width: 2rem; height: 2rem"
                  variant="primary"
                  label="Large Spinner"
                  type="grow"
                  class="mt-2"
                />
                <span class="sr-only">Chargement en cours...</span>
              </div>
            </template>
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(plaignant)="data">
              {{ data.item.user.lastname }} {{ data.item.user.firstname }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-button
                v-if="data.item.statut == 'En Cours'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                size="sm"
                class="float-right mb-1"
                :to="{ name: 'espace-expert.detail-expertise', params: { id: data.item.id } }"
              >
                Poursuivre le traitement
                <feather-icon icon="ArrowRightCircleIcon" />
              </b-button>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Par Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="expertisesSoumises.length"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 pagination-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ref, onMounted, reactive } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BRow,
  BCol, BCard, BButton, BCardText, BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BCardBody,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-cycle
// import useOffres from '@/services/affreteur/offresService'
// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'
// eslint-disable-next-line import/no-cycle
import useAuthUser from '@/services/authentification/userService'

// import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardBody,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // StatisticCardVertical,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: false,
      filtered: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    const {
      getAuthUser, status,
    } = useAuthUser()
    getAuthUser()
    // const userCon = user.value[0]
    this.status = status
  },
  setup() {
    const {
      loader, changeStatusExpertise, commentaires, createCommentaire, getAllExpertises, expertises, expertisesSoumises,
    } = useSocieteTransporteurs()
    const {
      getAuthUser, user, status,
      getExpert,
      expert,
    } = useAuthUser()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'reference', label: 'REFERENCE', sortable: true },
      { key: 'type_expertise.libelle', label: 'TYPE D\'EXPERTISE', sortable: true },
      { key: 'question', label: 'OBJET', sortable: true },
      { key: 'created_at', label: 'DTE DE PLAINTE', sortable: true },
      { key: 'actions' },
    ]
    // eslint - disable global-require /
    const changerStatusExpertise = async item => {
      await changeStatusExpertise(item.id, { statut: 'En Cours' })
      await createCommentaire({
        expertise_id: item.id,
        reponse_a: null,
        expert_id: user.value.id,
        description: `Bonjour, comment allez-vous? je vous écris pour vous porter assistance dans votre demande d'expertise ${item.reference}`,
      })
    }
    const form = reactive({
      expertise_id: '',
      reponse_a: null,
      expert_id: null,
      description: '',
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    onMounted(async () => {
      await getAuthUser()
      loader.value = true
      if (status.value) {
        await getExpert()
        await getAllExpertises(expert.value.data.domaine_expertise, 'En Cours', true, user.value.id)
      }
    })
    const getUser = async () => {
      await getAuthUser()
      return user.value.status
    }
    const formatter = value => value.toUpperCase()

    return {
      // user,
      getUser,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      changerStatusExpertise,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      getExpert,
      expert,
      infoModal,
      fields,
      // items,
      onFiltered,
      formatter,
      loader,
      form,
      user,
      commentaires,
      createCommentaire,
      expertises,
      expertisesSoumises,
    }
  },
  methods: {

  },
}
</script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  <style>
  @import '../../../../assets/css/chat.css';
  </style>
